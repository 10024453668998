import React, { useEffect } from "react";
import i18n from "../i18n";
import { MaskedInput, TextInput } from "wg-fe-ui";
import styled from "styled-components";
import FlowAddressContainer from "./FlowAddressContainer";

const FlowOfficeInfoValidationFields = ({
  defaultValues,
  errors,
  handleChange,
  values
}) => {
  useEffect(() => {
    setDefaultValues();
  }, [defaultValues]);

  function setDefaultValues() {
    Object.keys(defaultValues).map(name => {
      handleChange({ name, value: defaultValues[name] });
    });
  }
  return (
    <>
      <TextInput
        data-test-id="office_info_company_name"
        name="company"
        type="text"
        error={errors.company}
        value={values.company}
        onChange={handleChange}
      >
        {i18n.t("Company name")} *
      </TextInput>
      <MaskedInput
        data-test-id="office_info_cbe"
        name="cbe"
        error={errors.cbe}
        mask="9999.999.999"
        onChange={handleChange}
        value={values.cbe}
      >
        {i18n.t("Company registration number")} *
      </MaskedInput>
      <TextInput
        name="telephonenr"
        error={errors.telephonenr}
        onChange={val => handleChange(val)}
        value={values.telephonenr}
      >
        {i18n.t("Phonenumber")}
      </TextInput>
      <FlowAddressContainer
        data-test-id="office_info_address"
        name="address"
        errors={errors}
        values={values}
        handleChange={handleChange}
      />
      <Required>* {i18n.t("Required fields")}</Required>
    </>
  );
};

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 0;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

FlowOfficeInfoValidationFields.defaultProps = {
  errors: {},
  values: {},
  handleChange: () => {},
  handleFormValues: () => {},
  defaultValues: {}
};

export default FlowOfficeInfoValidationFields;
