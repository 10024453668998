import React from "react";
import styled from "styled-components";

import InfoPopup from "./InfoPopup";

import weGroupLogo from "../assets/images/wegroup-logo.svg";
const ComponentName = ({ chatText, infoHelper }) => {
  return (
    <LeftSplit>
      <WeGroupLogo src={weGroupLogo} alt="WeGroup Logo" />
      <RoundImage
        src="https://files.wegroup.be/avatars/avatar-louise.svg"
        alt="louise"
      />
      <TextBalloonWrapper>
        <TextBalloon dangerouslySetInnerHTML={{ __html: chatText }} />
        {infoHelper ? <StyledInfoPopup></StyledInfoPopup> : null}
      </TextBalloonWrapper>
    </LeftSplit>
  );
};

const StyledInfoPopup = styled(InfoPopup)`
  margin-left: 2rem;
`;

const WeGroupLogo = styled.img`
  height: 5.5rem;
`;

const TextBalloon = styled.p`
  font-size: 2rem;
  line-height: 1.25;
  width: 100%;
  ${'' /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); */}
  border: 1px solid #E4E4E4;
  box-shadow: 0px 6px 16px rgba(181, 181, 181, 0.25);
  padding: 2rem;
  border-radius: 0 1.5rem 1.5rem 1.5rem;
  color: black;
  background: #fff;
  > span {
    font-weight: 700;
  }
  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

const TextBalloonWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 80%;
  }
  
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

const LeftSplit = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding-right: 5rem;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin-bottom: 5rem;
  }

`;

const RoundImage = styled.img`
  max-width: 30rem;
  border-radius: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    display: None
  }
`;

export default ComponentName;
