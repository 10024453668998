import React from "react";
import i18n from "../i18n";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import { TextInput } from "wg-fe-ui";

const LoginUserValidationFields = ({ errors, values, handleChange }) => {
  return (
    <>
      <TextInput
        data-test-id="social_login_first_name"
        name="firstName"
        type="text"
        error={errors.firstName}
        value={values.firstName}
        onChange={handleChange}
      >
        {i18n.t("First name")} *
      </TextInput>
      <TextInput
        data-test-id="social_login_last_name"
        name="lastName"
        type="text"
        error={errors.lastName}
        value={values.lastName}
        onChange={handleChange}
      >
        {i18n.t("Last name")} *
      </TextInput>
      <TextInput
        data-test-id="social_login_email"
        name="email"
        type="email"
        error={errors.email}
        value={values.email}
        onChange={handleChange}
      >
        {i18n.t("Email address to be used at login")} *
      </TextInput>
      <Required>* {i18n.t("Required fields")}</Required>
      <CaptchaContainer>
        <Captcha
          sitekey="6LfXtssUAAAAAHPd30jLlGBYw2BjuyOu_QAqcRDq"
          onChange={e => handleChange({ name: "captcha", value: e })}
          hl={i18n.language}
        />
      </CaptchaContainer>
    </>
  );
};

const CaptchaContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
`;

const Captcha = styled(ReCAPTCHA)`
  align-self: flex-end;
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 0;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

LoginUserValidationFields.defaultProps = {
  errors: {},
  values: {},
  handleChange: () => {}
};

export default LoginUserValidationFields;
