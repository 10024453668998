import * as Yup from "yup";
import i18n from "../i18n";
import regex from "../helpers/regexService.js";

export const string = {
  required: Yup.string()
    .required(i18n.t(`required`))
    .nullable(true),
  notRequired: Yup.string().nullable(true)
};

export const number = {
  required: Yup.number().required(i18n.t(`required`)),
  notRequired: Yup.number()
};

export const date = Yup.object().required(i18n.t(`required`));

export const firstName = Yup.string()
  .required(i18n.t(`required`))
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const lastName = Yup.string()
  .required(i18n.t(`required`))
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const email = Yup.string()
  .email(i18n.t(`incorrect format`))
  .required(i18n.t(`required`))
  .min(2, i18n.t(`too short`))
  .max(100, i18n.t(`too long`));

export const password = Yup.string()
  .required(i18n.t(`required`))
  .min(2, i18n.t(`too short`))
  .max(100, i18n.t(`too long`));

export const year = {
  required: Yup.string()
    .required(i18n.t(`required`))
    .min(4, i18n.t(`too short`))
    .max(4, i18n.t(`too long`)),
  notRequired: Yup.string()
    .min(4, i18n.t(`too short`))
    .max(4, i18n.t(`too long`))
};

export const cbe = {
  required: Yup.string()
    .matches(/0[0-9]{3}.[0-9]{3}.[0-9]{3}/, i18n.t(`incorrect format`))
    .required(i18n.t(`required`)),
  notRequired: Yup.string()
    .nullable()
    .matches(/0[0-9]{3}.[0-9]{3}.[0-9]{3}/, i18n.t(`incorrect format`))
  }

export const crm = Yup.string()
  .required(i18n.t(`required`));

export const companyName = Yup.string()
  .required(i18n.t(`required`))
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const captcha = Yup.string(i18n.t(`required`))
  .matches(/[0-9a-zA-Z_-]{40}/)
  .nullable()
  .required(i18n.t(`required`));

export const website = {
  required: Yup.string(i18n.t(`required`))
    .matches(regex.website, i18n.t(`incorrect format`))
    .nullable()
    .required(i18n.t(`required`)),
  notRequired: Yup.string()
    .matches(regex.website, i18n.t(`incorrect format`))
    .nullable()
};

export const fileInput = Yup.string().required(i18n.t(`required`));
