import React from "react";
import styled from "styled-components";
// import { renderToStaticMarkup } from "react-dom/server";
// import { getThemeObject } from "../helpers/themingService";
// import BrokerBackground from "../assets/images/bg-broker";
// import { setBrokerData } from "../helpers/brokerDataService";
import Div100vh from "react-div-100vh";
import FlowRoutes from "./FlowRouter";
import FlowProgressBar from "../components/FlowProgressBar";

const Flow = () => {
  // const dataUri = `url("data:image/svg+xml,${encodeURIComponent(
  //   renderToStaticMarkup(<BrokerBackground colors={getThemeObject()} />)
  // )}")`;
  return (
    <Div100vh>
      <Background
      // img={dataUri}
      >
        <FlowProgressBar />
        <ContentContainer>
          <FlowRoutes />
        </ContentContainer>
      </Background>
    </Div100vh>
  );
};

const ContentContainer = styled.div`
  max-width: 1440px;
  width: 90%;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  max-height: 1024px;
  height: 80%;
  background-color: white;
  overflow: hidden;

  @media screen and (max-width: 425px) {
    max-height: none;
    height: 95%;
  }
`;

const Background = styled.main`
  /* background-image: ${(props) => props.img},
    linear-gradient(
      180deg,
      ${({ theme }) => theme.brand.primary} 45.86%,
      ${({ theme }) => theme.brand.secondary} 100%
    ); */
  background-color: #FF8000;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.font};
`;
export default Flow;
