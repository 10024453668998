import React from "react";
import styled from "styled-components";

const UploadButtonIcon = () => {
  return (
    <UploadIcon
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill={"black"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M38 23H27V12H23V23H12V27H23V38H27V27H38V23Z" />
    </UploadIcon>
  );
};

const UploadIcon = styled.svg`
  align-self: center;
  height: 2rem;
  margin-left: 1rem;
  /* margin-right: 2.5rem; */
  padding-right: 1rem;
  width: 4rem;
`;

export default UploadButtonIcon;
