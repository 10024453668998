import React from "react";
import { Route, Switch } from "react-router-dom";
import { retrieveStorage } from "../helpers/storeService";

import Login from "./Login.js";
import FlowOfficeInformation from "./FlowOfficeInfo.js";
import FlowOfficeEmailInfo from "./FlowOfficeEmailInfo.js";
import FlowOfficeUploadInfo from "./FlowOfficeUploadInfo.js";
import FlowOfficeCreatePassword from "./FlowOfficeCreatePassword.js";
import FlowChooseTheme from "./FlowChooseTheme.js";
import FlowCheckoutOverview from "./FlowCheckoutOverview.js";
import FlowThankyouPage from "./FlowThankyouPage.js";

import FlowRouterRedirect from "../components/FlowRouterRedirect";

const FlowRouter = () => {
  const { id } = retrieveStorage() || "";

  return (
    <Switch>
      <Route path="/verify-user" component={Login} />
      <Route
        path="/session/office-information/:id"
        component={FlowOfficeInformation}
      />
      <Route
        path="/session/office-email-info/:id"
        component={FlowOfficeEmailInfo}
      />
      <Route
        path="/session/office-upload-info/:id"
        component={FlowOfficeUploadInfo}
      />
      <Route
        path="/session/office-choose-theme/:id"
        component={FlowChooseTheme}
      />
      <Route
        path="/session/office-create-password/:id"
        component={FlowOfficeCreatePassword}
      />
      <Route
        path="/session/office-checkout-overview/:id"
        component={FlowCheckoutOverview}
      />
      <Route
        path="/session/flow-thankyou-page/:id"
        component={FlowThankyouPage}
      />
      {/* <FlowRouterRedirect
        from="/session"
        to="/session/office-email-info"
        id={id}
        loginPath="/verify-user"
      />
      <FlowRouterRedirect
        from="/session"
        to="/session/office-upload-info"
        id={id}
        loginPath="/verify-user"
      /> */}
      <FlowRouterRedirect
        from="/session"
        to="/session/office-information"
        id={id}
        loginPath="/verify-user"
      />
      {/* <FlowRouterRedirect
        from="/session"
        to="/session/office-create-password"
        id={id}
        loginPath="/verify-user"
      /> */}
    </Switch>
  );
};

export default FlowRouter;
