export default [
  {
    title: "verify-user",
    step: 1,
    subStep: 1,
    nextRoutes: {
      1: "office-information"
    }
  },
  {
    title: "office-information",
    step: 2,
    subStep: 1,
    nextRoutes: {
      1: "office-email-info"
    }
  },
  {
    title: "office-email-info",
    step: 2,
    subStep: 2,
    nextRoutes: {
      1: "office-upload-info"
    }
  },
  {
    title: "office-upload-info",
    step: 2,
    subStep: 3,
    nextRoutes: {
      1: "office-choose-theme"
    }
  },
  {
    title: "office-choose-theme",
    step: 3,
    subStep: 1,
    nextRoutes: {
      1: "office-checkout-overview"
    }
  },
  {
    title: "office-checkout-overview",
    step: 3,
    subStep: 2,
    nextRoutes: {
      1: "office-create-password"
    }
  },
  {
    title: "office-create-password",
    step: 3,
    subStep: 3,
    nextRoutes: {
      1: "flow-thankyou-page"
    }
  },
  {
    title: "flow-thankyou-page",
    step: 4,
    subStep: 0,
    nextRoutes: {}
  }
];
