export function initializeStorage(id) {
  const storedData = JSON.parse(sessionStorage.getItem("storage")) || [];

  storedData.push({ id, data: {} });
  console.log(storedData);

  sessionStorage.setItem("storage", JSON.stringify(storedData));
  return [...storedData].pop();
}

export function retrieveStorage() {
  const storedData = JSON.parse(sessionStorage.getItem("storage")) || [];

  return [...storedData].pop();
}

export function patchStorage(payload, id) {
  const storedData = JSON.parse(sessionStorage.getItem("storage")) || [];

  const index = storedData.findIndex(x => x.id === id) || 0;

  const updatedData = Object.assign(storedData[index].data, payload);

  storedData[index].data = updatedData;

  sessionStorage.setItem("storage", JSON.stringify(storedData));
}

export function retrieveStorageById(id) {
  const storedData = JSON.parse(sessionStorage.getItem("storage")) || [];
  return storedData.filter(x => x.id === id)[0];
}
