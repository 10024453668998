import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ActionButton } from "wg-fe-ui";
import { getNestedObject } from '../helpers/objectService';
import i18n from "../i18n";
import WeGroupLogo from "../assets/images/wegroup-logo.svg";
import useRouting from "../hooks/useRouting";

const FlowOfficeInformationForm = ({ defaultValues }) => {
  const { renderNextRoute } = useRouting();

  const [previewLogo, setPreviewLogo] = useState(WeGroupLogo);
  const [fileName, setFileName] = useState('WeGroup_logo.svg');

  useEffect(() => {
    const distributionLogoType = getNestedObject(defaultValues, ['distributionLogo', 'type']);
    const distributionLogoName = getNestedObject(defaultValues, ['distributionLogo', 'filename']);
    const distributionLogoBase64 = getNestedObject(defaultValues, ['distributionLogo', 'base64']);

    if (getNestedObject(defaultValues, ['distributionLogo', 'blob'])) {
      setPreviewLogo(`data:${distributionLogoType};base64, ${distributionLogoBase64}`);
      setFileName(distributionLogoName);
    }
  }, [defaultValues]);

  function formSubmitHandler(e) {
    e.preventDefault();
    renderNextRoute();
  }

  const address1 = defaultValues.streetName + " " + defaultValues.streetNumber + ", ";
  const address2 = defaultValues.postalCode + " " + defaultValues.municipalityName;

  return (
    <Form onSubmit={formSubmitHandler}>
      <InfoContainer>
        <PersonalInfo>
          <h2>{i18n.t("Personal Info")}</h2>
          <DataWrapper>
            <Name>
              <Label>{i18n.t("First name")}</Label>
              <Value>{defaultValues.firstName}</Value>
            </Name>
            <Name>
              <Label>{i18n.t("Last name")}</Label>
              <Value>{defaultValues.lastName}</Value>
            </Name>
            <Name>
              <Label>{i18n.t("Email")}</Label>
              <Value>{defaultValues.email}</Value>
            </Name>
          </DataWrapper>
        </PersonalInfo>
        <Divider />
        <CompanyInfo>
          <h2>{i18n.t("Company Info")}</h2>
          <DataWrapper>
            <Name>
              <Label>{i18n.t("Name")}</Label>
              <Value>{defaultValues.company}</Value>
            </Name>
            <Name>
              <Label>{i18n.t("CBE Number")}</Label>
              <Value>{defaultValues.cbe}</Value>
            </Name>
            <Name>
              <Label>{i18n.t("Address")}</Label>
              <Value>
                {address1}
                <br />
                {address2}
              </Value>
            </Name>
          </DataWrapper>
        </CompanyInfo>
      </InfoContainer>
      <Customization>
          <h2>{i18n.t("Customization")}</h2>
          <CustomizationDataContainer>
            <CustomizationData>
              <CustomName>
                <Label>{i18n.t("Your avatar")}</Label>
                <ImageValue>Louise</ImageValue>
                <RoundImage
                  src="https://files.wegroup.be/avatars/avatar-louise.svg"
                  alt="louise"
                />
              </CustomName>
            </CustomizationData>
            <CustomizationData>
              <CustomName>
                <Label>{i18n.t("Your logo")}</Label>
                <ImageValue>{fileName}</ImageValue>
              </CustomName>
              <AvatarImage src={previewLogo} />
            </CustomizationData>
          </CustomizationDataContainer>
        </Customization>
      <ButtonContainer>
        <ActionButton
          type="submit"
          value="Submit"
          data-test-id="drivers_information_submit"
        >
          {i18n.t("Checkout")}
        </ActionButton>
      </ButtonContainer>
    </Form>
  );
};

const Divider = styled.div`
  width: 1px;
  height: 100%;
  background: rgba(0, 0, 0, .3);

  @media (max-width: 768px) {
    height: inherit;
  }
`;

const CustomName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  text-align: center;
  @media (max-width: 768px) {
    height: 5rem;
  }
`;

const RoundImage = styled.img`
  margin: auto;
  max-width: 6rem;
  border-radius: 100%;
`;

const AvatarImage = styled.img`
  margin: auto;
  max-width: 15rem;
`;
const CustomizationDataContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: auto;
  @media (max-width: 768px) {
    
  }
`;

const CustomizationData = styled.div``;

const DataWrapper = styled.div`
  /* padding-left: 3rem; */
  text-align: center;
  width: 49%;
`;

const Label = styled.p`
  font-size: 1.35rem;
  color: grey;
  @media (max-width: 768px) {
    font-size: 1.15rem;
  }
`;
const Value = styled.p`
  font-size: 1.55rem;
  padding-top: 1rem;
  word-break: break-all;
  @media (max-width: 768px) {
    font-size: 1.35rem;
  }
`;

const ImageValue = styled.p`
  font-size: 1.55rem;
  padding: 1rem 0 2rem;
  word-break: break-all;
  @media (max-width: 768px) {
    font-size: 1.35rem;
  }
`;

const Name = styled.div`
  height: 5.5rem;
  margin-top: 1rem;
  @media (max-width: 768px) {
    height: 4.5rem;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 1.4rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0 2rem;
  }
`;

const PersonalInfo = styled.div`
  height: 95%;
  padding-top: 1rem;
  > h2 {
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    padding-bottom: 1rem;
    /* padding-left: 2rem; */
    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  & > div {
    width: 100%;
  }
`;

const CompanyInfo = styled.div`
  padding-top: 1rem;
  > h2 {
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    padding-bottom: 1rem;
    /* padding-left: 2rem; */
    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  & > div {
    width: 100%;
  }
`;

const Customization = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  
  margin: auto;
  margin-bottom: 0;
  margin-top: 2rem;
  padding-top: 2rem;
  width: 100%;

  > h2 {
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
`;

const ButtonContainer = styled.div`
  margin-top: auto;
  align-self: flex-end;

  & button {
    width: 20rem;

    @media (max-width: 768px) {
      margin-top: 5rem;
    }

    @media screen and (max-width: 425px) {
      width: 100%;
      margin-top: 2rem;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (max-width: 768px) {
    height: unset;
  }
`;

export default FlowOfficeInformationForm;
