import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import i18n from "../i18n";

import FlowOfficeEmailInfoForm from "../components/FlowOfficeEmailInfoForm.jsx";
import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";

import useRouting from "../hooks/useRouting";
import { patchStorage, retrieveStorageById } from "../helpers/storeService";

const FlowOfficeEmailInfo = () => {
  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { id } = useParams();

  useEffect(() => {
    const { data } = retrieveStorageById(id);
    setDefaultValues(data);
  }, []);

  function handleSubmit(values) {
    patchStorage(values, id);
    window.Autopilot.run('associate', {
      Email: values?.email,
      Website: values?.website,
      custom: {
        'string--Beheerspakket': values?.crm,
        'string--Kantoor--email': values?.infoMail,
      },
    });
    renderNextRoute();
  }

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          `Nice to meet you Jan Can you tell me a little more about your brokerage office`,
          {
            firstName: defaultValues.firstName
          }
        )}
      />
      <Divider />
      <RightSplit>
        <FlowOfficeEmailInfoForm
          handleFormSubmit={val => {
            handleSubmit(val);
          }}
          defaultValues={defaultValues}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
  overflow-y: auto;
  padding: 5rem;

  @media (max-width: 768px) {
    display: block;
    padding: 3rem 5rem;
  }

  @media screen and (max-width: 425px) {
    padding: 3rem 2.5rem;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 100%;
  background: rgba(0, 0, 0, .3);

  @media (max-width: 768px) {
    display: none;
  }
`;

const RightSplit = styled.div`
  width: 50%;
  padding-left: 5rem;
  overflow-y: auto;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding-top: 1px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

export default FlowOfficeEmailInfo;
