import React, { Suspense, useEffect, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { verifyInit } from "../helpers/apiRouterService";
import { loggedIn, setLoggedIn } from "../helpers/authService";

import Flow from "../pages/Flow";
import LoadingSpinner from "../components/LoadingSpinner.jsx";

function PrivateRoute({ component: Component, ...rest }) {
  const [authorized, setAuthorized] = useState("initial"); // initial / authenticated / forbidden

  useEffect(() => {
    loggedIn ? setAuthorized("authenticated") : fetchAuthorization();
  }, []);
  async function fetchAuthorization() {
    const [, status] = await verifyInit();
    if (status === 200) setAuthorized("authenticated");
    else setAuthorized("forbidden");
  }

  switch (authorized) {
    case "initial":
      return <LoadingSpinner />;
    case "authenticated":
      setLoggedIn(true);
      return <Route {...rest} component={Component} />;
    case "forbidden":
      return <Redirect to="/verify-user" />;
  }
}

const Main = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Router>
        <Switch>
          <Route path="/verify-user" component={Flow} />
          <PrivateRoute path="/session" component={Flow} />
          <Redirect exact from="/" to="/session" />
        </Switch>
      </Router>
    </Suspense>
  );
};
export default Main;
