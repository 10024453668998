import "isomorphic-fetch";
import { BASE_URL } from "../env.config";

import { setLoggedIn } from "./authService";

const headers = {
  "Content-Type": "application/vnd.api+json",
  Accept: "application/vnd.api+json",
};

export async function refresh() {
  const path = "v1/api/aut/v1/api/auth/refresh";
  const payload = {};

  if (sessionStorage.getItem("access_token")) {
    payload.refresh_token = sessionStorage.getItem("refresh_token");
  }

  const [resp, status] = await request(path, "POST", payload);
  if (status === 200)
    sessionStorage.setItem("access_token", resp?.access_token);
  return [resp, status];
}

export async function request(path, method, payload = null) {
  let [resp, status] = await _request(path, method, payload);
  if (status >= 401) {
    if (resp.msg === "INVALID_TOKEN" || resp.msg === "NOT_AUTHORIZED") {
      return await logout();
    } else if (resp.msg === "AUTHENTICATION_EXPIRED") {
      await refresh();
      [resp, status] = await _request(path, method, payload);
    }
  }
  return [resp, status];
}

export async function logout() {
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("refresh_token");
  setLoggedIn(false);
  window.location.href = "/";
}

async function _request(path, method, payload) {
  const url = BASE_URL + path;
  let tempHeaders = {};
  let requestOptions = {
    method: method,
    credentials: "include",
  };

  if (sessionStorage.getItem("access_token")) {
    tempHeaders.Authorization = `Bearer ${sessionStorage.getItem(
      "access_token"
    )}`;
  }

  if (payload) {
    if (payload instanceof FormData) {
      requestOptions.body = payload;
    } else {
      requestOptions.body = JSON.stringify(payload);
    }
  }

  if (!(payload instanceof FormData)) {
    tempHeaders = {
      ...tempHeaders,
      ...headers,
    };
  }

  requestOptions.headers = tempHeaders;

  const req = await fetch(url, requestOptions);

  const resp = await req.json();
  return [resp, req.status];
}
