import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import i18n from "../i18n";
import * as Yup from "yup";
import useForm from "../hooks/useForm";
import { ActionButton } from "wg-fe-ui";
import { fileInput } from "../constants/validationSchemas.js";
import UploadButtonIcon from "./UploadButtonIcon";

import WeGroupLogo from "../assets/images/wegroup-logo.svg";
import { patchStorage, retrieveStorageById } from "../helpers/storeService";

const FlowOfficeUploadInfoForm = ({ handleFormSubmit, defaultValues }) => {
  const { id } = useParams();
  const [previewImage, setpreviewImage] = useState();

  const [buttonText, setButtonText] = useState("Upload Logo");
  const [errorMsg, setErrorMsg] = useState("");

  const SignupSchema = Yup.object().shape({
    logo: fileInput
  });

  const { handleChange, errors, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {}
  });

  useEffect(() => {
    const { data } = retrieveStorageById(id);
    if (data.distributionLogo !== undefined) {
      setpreviewImage(`data:${data.distributionLogo.type};base64, ${data.distributionLogo.base64}`);
    } else {
      setpreviewImage(WeGroupLogo);
    }
    setDefaultValues(data);
  }, [defaultValues]);

  function setDefaultValues() {
    Object.keys(defaultValues).map(name => {
      handleChange({ name, value: defaultValues[name] });
    });
  }

  function uploadFile(e) {
    let buttonText = "";
    let errorMsg = "";
    const maxFileSize = 3000000;
    const file = e.target.files[0];
    const imageTypes = ['image/svg', 'image/jpeg', 'image/jpg', 'image/png'];

    if (!!file) {
      buttonText = "Change Logo";
    } else {
      buttonText = "Upload Logo";
    }

    if (file.size > maxFileSize) {
      errorMsg = 'File size must not exceed 3MB.';
    } else if (!imageTypes.includes(file.type)) {
      errorMsg = 'File type most either be a jpg, jpeg, png or svg.';
    } else {
      const previewImgSrc = e.target.files[0].name; 
      const distributionLogoBlob = URL.createObjectURL(e.target.files[0]);
      
      setpreviewImage(distributionLogoBlob);

      var reader = new FileReader();
      var binaryDataReceipt = null;
      reader.onload = function(e) {
        if (!e) {
            binaryDataReceipt = reader.content;
        }
        else {
            binaryDataReceipt = e.target.result;
        }
        
        const distributionLogo = window.btoa(binaryDataReceipt);

        console.log(distributionLogoBlob);

        patchStorage(
          {
            distributionLogo: {
              blob: distributionLogoBlob,
              type: `image/${previewImgSrc
                .split(".")
                [previewImgSrc.split(".").length - 1].toLowerCase()}`,
              base64: distributionLogo,
              filename: previewImgSrc.replace(/ /g, "_").toLowerCase()
            }
          },
          id
        );
        
      };

      reader.readAsBinaryString(e.target.files[0]);

    }

    setButtonText(buttonText);
    setErrorMsg(errorMsg);
  }

  function formSubmitHandler(e) {
    e.preventDefault();
    handleFormSubmit();

    const { data: _data } = retrieveStorageById(id);
    if (_data.distributionLogo == undefined) {
      patchStorage(
        {
          distributionLogo: WeGroupLogo
        },
        id
      );
    }
  }

  const clearLogo = (e) => {
    e.preventDefault();
    patchStorage(
      {
        distributionLogo: WeGroupLogo
      },
      id
    );
    setpreviewImage(WeGroupLogo);
  };

  return (
    <Form onSubmit={formSubmitHandler}>
      <Header>
        <h1>{i18n.t("Preview")}</h1>
        <h3>{i18n.t("of your custom logo")}</h3>
      </Header>
      <LogoContainer>
        <ImageContainer>
          <ImgPreview
            src={previewImage}
            alt="Your logo"
          />
        </ImageContainer>
        <StyledInput
          id="file-upload"
          name="logo"
          onChange={e => uploadFile(e)}
          accept="image/png, image/jpeg, image/svg, image/jpg"
          type="file"
          errors={errors.avatar}
          values={values.avatar}
        />
        <Inputwrapper>
          <InputLabel htmlFor="file-upload">
            <UploadButtonIcon />
            <p>{`${i18n.t(buttonText)} - max 3MB`}</p>
          </InputLabel>
          <StyledButton onClick={clearLogo}>Clear logo</StyledButton>
          <ErrorMsg>{i18n.t(errorMsg)}</ErrorMsg>
        </Inputwrapper>
      </LogoContainer>
      <ButtonContainer>
        <ActionButton
          type="submit"
          value="Submit"
          data-test-id="drivers_information_submit"
        >
          {i18n.t("Next")}
        </ActionButton>
      </ButtonContainer>
    </Form>
  );
};

const Header = styled.div`
  h1 {
    font-weight: 800;
    font-size: 2rem;
  }
  h3 {
    color: grey;
    margin-top: 0.75rem;
  }
`;

const ButtonContainer = styled.div`
  margin-top: auto;
  align-self: flex-end;

  & button {
    width: 20rem;

    @media (max-width: 768px) {
      margin-top: 5rem;
    }

    @media screen and (max-width: 425px) {
      width: 100%;
      margin-top: 2rem;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledButton = styled.a`
   margin-top: 2rem;
   font-size: 1.4rem;

   &:hover {
     cursor: pointer;
     text-decoration: underline;
     color: #ff8000;
   }
`;

const ErrorMsg = styled.p`
  color: #f74040;
  font-size: 1.2rem;
  margin: 1rem 0;
  text-align: end;
`;

const ImageContainer = styled.div`
  margin-top: 4rem;
  max-width: 70%;
  min-width: 50%;
`;

const ImgPreview = styled.img`
  width: 100%;
  height: 100%;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    max-height: 20rem;
    max-width: 30rem
  }
`;

const InputLabel = styled.label`
  transition: .15s;
  align-items: center;
  background-color: #cacaca;
  border: 0;
  border-radius: 0.5rem;
  color: black;
  justify-content: space-evenly;
  word-break: keep-all;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  height: 4rem;
  line-height: 2rem;
  min-width: 14rem;
  padding: 1rem 0.4rem;
  text-align: center;
  width: 80%;
  p {
    margin-right: 3rem;
    text-align: center;
    width: 30rem;
  }

  &:hover {
    cursor: pointer;
    background-color: #b9b9b9;
  }
`;

const Inputwrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  width: 100%;
  align-items: center;
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-top: -5rem;
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 0rem;
  }
`;

const StyledInput = styled.input`
  display: none;
  opacity: 0;
`;

FlowOfficeUploadInfoForm.defaultProps = {
  errors: {},
  values: {},
  handleChange: () => {}
};

export default FlowOfficeUploadInfoForm;
