import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import styled from "styled-components";
import { ActionButton } from "wg-fe-ui";
import * as Yup from "yup";
import i18n from "../i18n";
import FlowOfficeEmailInfoValiFields from "./FlowOfficeEmailInfoValiFields";

import { retrieveStorageById } from "../helpers/storeService";

import { email, website, crm } from "../constants/validationSchemas.js";
import useForm from "../hooks/useForm";

const FlowOfficeInformationForm = ({ handleFormSubmit, defaultValues }) => {
  const { id } = useParams();

  const SignupSchema = Yup.object().shape({
    claimsMail: email,
    infoMail: email,
    website: website.notRequired,
    complaintsMail: email,
    crm: crm
  });

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {}
  });

  useEffect(() => {
    const { data } = retrieveStorageById(id);
    setDefaultValues(data);
  }, [defaultValues]);

  function setDefaultValues() {
    Object.keys(defaultValues).map(name => {
      handleChange({ name, value: defaultValues[name] });
    });
  }

  function formSubmitHandler(e) {
    e.preventDefault();
    handleSubmit(handleFormSubmit);
  }

  return (
    <Form onSubmit={formSubmitHandler}>
      <FlowOfficeEmailInfoValiFields
        className="emailWebsite"
        handleChange={handleChange}
        values={values}
        errors={errors}
        handleFormValues={handleSubmit}
        defaultValues={defaultValues}
      />
      <ButtonContainer>
        <ActionButton
          type="submit"
          value="Submit"
          data-test-id="drivers_information_submit"
        >
          {i18n.t("Next")}
        </ActionButton>
      </ButtonContainer>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: auto;
  align-self: flex-end;

  & button {
    width: 20rem;

    @media (max-width: 768px) {
      margin-top: 5rem;
    }

    @media screen and (max-width: 425px) {
      width: 100%;
      margin-top: 2rem;
    }
  }
`;

FlowOfficeInformationForm.defaultProps = {
  handleFormValues: () => {},
  defaultValues: {}
};

export default FlowOfficeInformationForm;
