import React, { useEffect, useState } from "react";
import i18n from "../i18n";
import { TextInput, CheckBox, SearchSelectInput } from "wg-fe-ui";
import styled from "styled-components";

const FlowOfficeEmailInfoValiFields = ({
  defaultValues,
  errors,
  handleChange,
  values
}) => {
  const [isEmailSame, setIsEmailSame] = useState(false);
  const SelectPlaceholder = i18n.t("Choose your option");

  const crmOptions = [
    { value: "BRIO", label: "Brio" },
    { value: "BRIO_PLUS", label: "Brio +" },
    { value: "SIGURA", label: "Sigura" },
    { value: "BROKERCLOUD", label: "BrokerCloud" },
    { value: "COBRA", label: "Cobra" },
    { value: "ASSAPP", label: "AssApp" },
    { value: "SIREUS", label: "Sireus" },
    { value: "INSUSOFT", label: "Insusoft" },
    { value: "BROKER_STAR", label: "Broker Star" },
    { value: "FIREANTZ", label: "FireAntz" },
    { value: "WILLEMOT", label: "Willemot CRM" },
    { value: "OTHER", label: i18n.t(`Others`) },
    { value: "NONE", label: i18n.t(`None`) }
  ];

  useEffect(() => {
    setDefaultValues();
  }, [defaultValues]);

  function setDefaultValues() {
    Object.keys(defaultValues).map(name => {
      handleChange({ name, value: defaultValues[name] });
    });
  }

  useEffect(() => {
    setClaimsAndComplaintsEmail(isEmailSame);
  }, [isEmailSame, values.infoMail]);

  function setClaimsAndComplaintsEmail(isEmailSame) {
    if (isEmailSame) {
      handleChange({ name: "complaintsMail", value: values.infoMail });
      handleChange({ name: "claimsMail", value: values.infoMail });
    } else {
      handleChange({ name: "complaintsMail", value: "" });
      handleChange({ name: "claimsMail", value: "" });
    }
  }

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return "";
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : "" });
  }

  return (
    <>
      <WebsiteInput
        data-test-id="office_info_website"
        name="website"
        type="text"
        error={errors.website}
        value={values.website ? values.website.split("https://").pop() : ""}
        onChange={({ name, value }) => {
          handleChange({
            name,
            value: `https://${value}`
          });
        }}
      >
        <WebsiteContainer>{i18n.t("Website")}</WebsiteContainer>
      </WebsiteInput>
      <TextInput
        className="infoMail"
        data-test-id="office_info_email"
        name="infoMail"
        type="email"
        error={errors.infoMail}
        value={values.infoMail}
        onChange={handleChange}
      >
        {i18n.t("CompanyEmail")} *
      </TextInput>
      <CheckBoxEmail
        className="checkbox"
        checked={isEmailSame}
        onChange={() => {
          setIsEmailSame(!isEmailSame);
        }}
        name="isEmailSame"
      >
        {i18n.t("Utilize this email-address for claims and complaints")}
      </CheckBoxEmail>
      <TextInput
        className="complaintsMail"
        data-test-id="office_info_complaints_email"
        name="complaintsMail"
        type="email"
        error={errors.complaintsMail}
        value={isEmailSame ? values.infoMail : values.complaintsMail}
        onChange={handleChange}
        disabled={isEmailSame}
      >
        {i18n.t("Complaints Email")} *
      </TextInput>
      <TextInput
        className="claimsMail"
        data-test-id="office_info_claims_email"
        name="claimsMail"
        type="email"
        error={errors.claimsMail}
        value={isEmailSame ? values.infoMail : values.claimsMail}
        onChange={handleChange}
        disabled={isEmailSame}
      >
        {i18n.t("Claims Email")} *
      </TextInput>
      <SelectInput
        error={errors.crm}
        name="crm"
        onChange={val => {
          handleSelectChange(val, "crm");
        }}
        options={crmOptions}
        placeholder={SelectPlaceholder}
        isClearable
        value={handleSelectValue(crmOptions, "crm")}
      >
        {i18n.t("What kind of crm system do you use")} *
      </SelectInput>
      <Required>* {i18n.t("Required fields")}</Required>
    </>
  );
};

const Required = styled.p`
font-size: 1.4rem;
color: ${({ theme }) => theme.typo.label};
margin-top: 0;
@media (max-width: 768px) {
margin: 0 2rem 2rem 2rem;
}
`;

const CheckBoxEmail = styled(CheckBox)`
  position: relative;
  margin-bottom: 1rem;
  top: -1.5rem;
`;

const WebsiteContainer = styled.span`
  position: relative;
  :before {
    position: absolute;
    content: "https://";
    top: 39px;
    font-size: 1.6rem;
    padding-left: .7rem;
  }
`;

const WebsiteInput = styled(TextInput)`
  input {
    padding-left: 5.8rem;
  }
`;

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

FlowOfficeEmailInfoValiFields.defaultProps = {
  errors: {},
  values: {},
  handleChange: () => {},
  handleFormValues: () => {},
  defaultValues: {}
};

export default FlowOfficeEmailInfoValiFields;
