if (FileReader.prototype.readAsBinaryString === undefined) {
  FileReader.prototype.readAsBinaryString = function (fileData) {
    var binary = "";
    var polyfillTarget = this;
    var reader = new FileReader();
    reader.onload = function (e) {
        var bytes = new Uint8Array(reader.result);
        var length = bytes.byteLength;
        for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        
        polyfillTarget.content = binary;
        polyfillTarget.onload();
    }
    reader.readAsArrayBuffer(fileData);
  }
}