import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import i18n from "../i18n";
import { getNestedObject } from "../helpers/objectService";
import { retrieveStorageById } from "../helpers/storeService.js";
import { ActionButton, LoadingSpinner } from "wg-fe-ui";
import LouiseThankyou from "../assets/icons/LouiseThankyouIcon.jsx";
import {
  createTrialBroker,
  uploadBrokerLogo,
  uploadBrokerLogoFe,
} from "../helpers/apiRouterService";
import ReactGA from 'react-ga';

const FlowThankyouPage = () => {
  const { id } = useParams();
  const { data } = retrieveStorageById(id) || {};
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    brokerCreationCalls();
  }, []);

  function parseCBE(cbe) {
    return cbe ? cbe.replace(/\./g, "") : cbe;
  }

  // user_id: "7801dcfb-e8eb-4be1-a36a-b251a8524f55"
  // distribution_id: "7b0aee8c-39ac-4566-ad90-9cfaf1e4367d"
  // token: "35a7a5f24157bf2e49d1d6f79e01738f9adeb0f9552ff585.XoJH0Q.XtdoETZN0Gxr09AOhR1A6NPQo7w"

  const b64toBlob = (dataURI) => {
    let byteString = window.atob(dataURI.split(",")[1].replace(/\s/g, ""));

    let mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];

    let arrayBuffer = new ArrayBuffer(byteString.length);
    let _ia = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
    }

    let dataView = new DataView(arrayBuffer);
    let blob = new Blob([dataView.buffer], { type: mimeString });

    return blob;
  };

  async function brokerCreationCalls() {
    const { distributionLogo } = data;
    const [{ distribution_id, token }, status] = await callTrialBrokerCreation();

    if (getNestedObject(data, ["distributionLogo", "blob"]) && status === 201) {
      const base64Image = `data:${distributionLogo.type};base64, ${distributionLogo.base64}`;

      let blobImage = b64toBlob(base64Image);

      let formData = new FormData();

      formData.append("file", blobImage, distributionLogo?.filename);

      const [, logoStatus] = await uploadBrokerLogo(
        distribution_id,
        token,
        formData
      );
      const [, logoFeStatus] = await uploadBrokerLogoFe(
        distribution_id,
        token,
        formData
      );

      setLoading(false);
      if (logoStatus > 201 || logoFeStatus > 201) {
        console.log('LOGO STATUS: ', logoStatus);
        console.log('LOGO-FE STATUS: ', logoFeStatus);
        setError(true);
        ReactGA.ga(`trialonboarding.send`, 'event', {
          eventCategory: 'Onboarding Flow',
          eventAction: 'Account creation failed',
          eventLabel: i18n.language,
        });
      } else {
        ReactGA.ga(`trialonboarding.send`, 'event', {
          eventCategory: 'Onboarding Flow',
          eventAction: 'Account creation succeeded',
          eventLabel: i18n.language,
        });
        window.Autopilot.run('associate', {
          Email: data?.email,
          custom: {
            'string--Plan': 'TRIAL_BROKER',
            'string--Role': 'Admin',
          },
        });    
      }
    } else {
      setLoading(false);
      ReactGA.ga(`trialonboarding.send`, 'event', {
        eventCategory: 'Onboarding Flow',
        eventAction: 'Account creation succeeded',
        eventLabel: i18n.language,
      });
      window.Autopilot.run('associate', {
        Email: data?.email,
        custom: {
          'string--Plan': 'TRIAL_BROKER',
          'string--Role': 'Admin',
        },
      });
    }
  }

  async function callTrialBrokerCreation() {
    const user = {
      email: data.email,
      language: i18n.language.toUpperCase(),
    };
    const password = data.password;
    const broker = {
      name: data.firstName + ` ` + data.lastName,
      phone: data.telephonenr,
      crm: data.crm,
      assistant_name: `Louise`,
    };
    const distribution = {
      name: data.company,
      email: data.infoMail,
      address: {
        street: data.streetName,
        zipcode: data.postalCode,
        housenr: data.streetNumber,
        country_code: "BE",
        boxnr: data.boxNumber,
        city: data.municipalityName,
      },
      fsma: {
        key: parseCBE(data.cbe),
      },
      cbe: parseCBE(data.cbe),
      website: data.website,
      complaints_email: data.complaintsMail,
      claims_email: data.claimsMail,
    };
    const payload = {
      user,
      password,
      broker,
      distribution,
    };

    const [resp, status] = await createTrialBroker(payload);
    if (status !== 201) {
      if (resp?.msg !== 'USER_EXISTS') {
        setLoading(false);
        setError(true);
        console.log('CREATE STATUS: ', status);
        console.log('CREATE RESP: ', resp);
        ReactGA.ga(`trialonboarding.send`, 'event', {
          eventCategory: 'Onboarding Flow',
          eventAction: 'Account creation failed',
          eventLabel: i18n.language,
        });
      }
    }
    return [resp, status];
  }

  function formSubmitHandler() {
    let URL = `https://marketplace.wegroup.be/login?lang=${i18n.language}`;
    window.location.href = URL;
  }

  const WelcomeLine1 = i18n.t(
    "Welcome to the group, your account has been succesfully created!"
  );

  return (
    <SplitContainer>
      <RightSplit>
        {loading ? (
          <LouiseContainer>
            <LouiseText>
              <LoadingSpinner />
              <p
                dangerouslySetInnerHTML={{
                  __html: i18n.t(
                    "Wait a second as we create your trial account"
                  ),
                }}
              />
            </LouiseText>
          </LouiseContainer>
        ) : error ? (
          <LouiseContainer>
            <LouiseText>
              <p
                dangerouslySetInnerHTML={{
                  __html: i18n.t(
                    "Something went wrong with creating your trial account please contact us"
                  ),
                }}
              />
            </LouiseText>
          </LouiseContainer>
        ) : (
          <>
            <LouiseContainer>
              <LouiseThankyou />
              <LouiseText>
                <p dangerouslySetInnerHTML={{ __html: WelcomeLine1 }}></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: i18n.t(
                      "In order to complete the process please locate the conformation link that we have sent to your email address"
                    ),
                  }}
                />
              </LouiseText>
            </LouiseContainer>
            <ButtonContainer>
              <ActionButton
                type="submit"
                value="Submit"
                onClick={formSubmitHandler}
                data-test-id="drivers_information_submit"
              >
                {i18n.t("Start with Louise")}
              </ActionButton>
            </ButtonContainer>
          </>
        )}
      </RightSplit>
    </SplitContainer>
  );
};

const LouiseText = styled.div`
  > p {
    margin-top: 2rem;
    > b {
      font-weight: 700;
      > span {
        color: #ff8000;
      }
    }
    > span {
      font-weight: bold;
    }
  }
`;

const LouiseContainer = styled.div`
  letter-spacing: 0.03rem;
  line-height: 2.5rem;
  margin: auto;
  text-align: center;
  width: 48%;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const ButtonContainer = styled.div`
  margin: auto;
  > button {
    width: 20rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
  overflow-y: auto;
`;

const RightSplit = styled.div`
  display: flex;

  flex-direction: column;

  overflow-y: auto;
  padding: 5rem;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

export default FlowThankyouPage;
