import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import i18n from "../i18n";
import { ActionButton, TextInput } from "wg-fe-ui";
import { patchStorage } from "../helpers/storeService";
import useRouting from "../hooks/useRouting";

import EyeIcon from "../assets/icons/eye-icon";
import CrossedEyeIcon from "../assets/icons/crossed-eye-icon";
import zxcvbn from "zxcvbn";

const FlowCreatePasswordForm = () => {
  const { id } = useParams();
  const { renderNextRoute } = useRouting();

  const [isPasswordTouched, setIsPasswordTouched] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [oneNumber, setOneNumber] = useState(false);
  const [oneUppercaseAndLowercase, setOneUppercaseAndLowercase] = useState(
    false
  );
  const [specialCharacter, setSpecialCharacter] = useState(false);
  const [tooShort, setTooShort] = useState(false);
  const [sequenceNotAllowed, setSequenceNotAllowed] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordScoreIsSufficient, setPasswordScoreIsSufficient] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [showEye, setShoweye] = useState(true);
  const [confirmShowEye, setConfirmShowEye] = useState(true);

  useEffect(() => {
    checkIfOneNumber(newPassword.value);
    checkIfOneUppercaseAndLowercase(newPassword.value);
    checkIfSpecialCharacter(newPassword.value);
    checkIfTooShort(newPassword.value);
    checkIfSequenceNotAllowed(newPassword.value);
    checkIfPasswordsMatch(newPassword.value);
    checkIfPackageScoreIsHighEnough(newPassword.value);
  }, [newPassword.value, confirmPassword.value]);

  function checkIfOneNumber() {
    const hasNumber = /\d/.test(newPassword.value);
    setOneNumber(hasNumber);
  }

  function checkIfOneUppercaseAndLowercase(newPasswordValue) {
    const hasUppercaseAndLowercase =
      /[a-z]/.test(newPasswordValue) && /[A-Z]/.test(newPasswordValue);
    setOneUppercaseAndLowercase(hasUppercaseAndLowercase);
  }

  function checkIfSpecialCharacter(newPasswordValue) {
    const hasSpecialCharacter = /[éèùàçâêîôûäëïöü?!@#$%^&*(),.~?":{}|<>/+\\;-]/.test(
      newPasswordValue
    );
    setSpecialCharacter(hasSpecialCharacter);
  }

  function checkIfPackageScoreIsHighEnough(newPasswordValue) {
    if (!newPasswordValue) return false;
    const pwdAnalysis = zxcvbn(newPasswordValue);
    console.log('Password analysis by zxcvbn:', pwdAnalysis);
    const isSufficient = pwdAnalysis.score >= 3;
    setPasswordScoreIsSufficient(isSufficient);
    return isSufficient;
  }

  function checkIfTooShort(newPasswordValue) {
    if (newPasswordValue !== undefined) {
      const passwordLength = newPasswordValue.split("").length;
      const MIN = 10;

      const isLongEnough = passwordLength >= MIN;
      setTooShort(isLongEnough);
    }
  }

  function checkIfSequenceNotAllowed(newPasswordValue) {
    if (newPasswordValue !== undefined) {
      let containsSequence = false;
      const notAllowedSequences = [
        "123",
        "1234",
        "5678",
        "abcd",
        "qwerty",
        "azerty",
        "qwertz"
      ];

      if (newPasswordValue === "") containsSequence = true;

      notAllowedSequences.map(sequence => {
        if (newPasswordValue.includes(sequence)) containsSequence = true;
      });

      setSequenceNotAllowed(!containsSequence);
    }
  }
  function checkIfPasswordsMatch() {
    if (newPassword.value === confirmPassword.value) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  }

  function formSubmitHandler(e) {
    e.preventDefault();
    let errorMsgPassword = "";
    const password = newPassword.value;
    const pwdAllowed = oneNumber && oneUppercaseAndLowercase && specialCharacter && tooShort && sequenceNotAllowed && passwordsMatch && passwordScoreIsSufficient
    if (password && pwdAllowed) {
      patchStorage({ password }, id);
      renderNextRoute();
    } else {
      errorMsgPassword = "Must set secure password before continuing";
    }
    setErrorMsg(errorMsgPassword);
  }

  return (
    <Form onSubmit={formSubmitHandler}>
      <div>
        <StyledTextInput
          type={showEye ? "text" : "password"}
          name="password"
          className="password"
          onFocus={() => {
            if (!isPasswordTouched) setIsPasswordTouched(true);
          }}
          onChange={val => setNewPassword(val)}
        >
          {i18n.t("Password")}
          <EyeButton
            onClick={
              // e => handlePasswordVisibility(e)
              () => setShoweye(!showEye)
            }
          >
            {showEye ? <EyeIcon /> : <CrossedEyeIcon />}
          </EyeButton>
        </StyledTextInput>
        <TextInput
          type={confirmShowEye ? "text" : "password"}
          name="confirmPassword"
          className="confirmPassword"
          onChange={val => setConfirmPassword(val)}
        >
          {i18n.t("Confirm Password")}
          <EyeButton onClick={() => setConfirmShowEye(!confirmShowEye)}>
            {confirmShowEye ? <EyeIcon /> : <CrossedEyeIcon />}
          </EyeButton>
        </TextInput>
        <ErrorMsg>{i18n.t(errorMsg)}</ErrorMsg>
      </div>
      <RequirementContainer>
        <P>{i18n.t("The following requirements must be met")}</P>
        <ul>
          <Li touched={isPasswordTouched} completed={oneNumber}>
            {i18n.t("At least one number (0 - 9)")}
          </Li>
          <Li touched={isPasswordTouched} completed={oneUppercaseAndLowercase}>
            {i18n.t("At least one uppercase and lowercase character")}
          </Li>
          <Li touched={isPasswordTouched} completed={specialCharacter}>
            {i18n.t("At least one special character ()")}
          </Li>
          <Li touched={isPasswordTouched} completed={tooShort}>
            {i18n.t("Too short password, min 10 characters")}
          </Li>
          <Li touched={isPasswordTouched} completed={sequenceNotAllowed}>
            {i18n.t(
              "Sequences are not allowed (for example 1234, azerty, abcd)"
            )}
          </Li>
          <Li touched={isPasswordTouched} completed={passwordsMatch}>
            {i18n.t("Password and confirm password must match")}
          </Li>
          <Li touched={isPasswordTouched} completed={passwordScoreIsSufficient}>
            {i18n.t("Password must not contain too much real text")}
          </Li>
        </ul>
      </RequirementContainer>

      <ButtonContainer>
        <ActionButton
          type="submit"
          value="Submit"
          data-test-id="drivers_information_submit"
        >
          {i18n.t("Create your account")}
        </ActionButton>
      </ButtonContainer>
    </Form>
  );
};
const StyledTextInput = styled(TextInput)`
  position: relative;
`;

const EyeButton = styled.div`
  position: absolute;
  right: 1rem;
  top: 2.88rem;
  user-select: none;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
`;

const ErrorMsg = styled.p`
  color: #f74040;
  font-size: 1.2rem;
  height: 1rem;
  margin-bottom: 1rem;
  margin-top: -1rem;
  text-align: end;
`;

const RequirementContainer = styled.div`
  margin: auto;
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

const ButtonContainer = styled.div`
  margin-top: auto;
  align-self: flex-end;

  & button {
    width: 20rem;

    @media (max-width: 768px) {
      margin-top: 5rem;
    }

    @media screen and (max-width: 425px) {
      width: 100%;
      margin-top: 2rem;
    }
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-rows: 17rem auto 5rem;
  height: 100%;
`;

const handleColorType = (completed, touched) => {
  switch (true) {
    case touched && completed:
      return "#2c7b2c";
    case touched && !completed:
      return "#ea3535";
    default:
      return "black";
  }
};

const P = styled.p`
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

const Li = styled.li`
  margin-left: 3rem;
  font-size: 1.6rem;
  margin-top: 1.5rem;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
  color: ${({ completed, touched }) => handleColorType(completed, touched)};
  ::before {
    content: "\u25CF";
    position: absolute;
    margin-left: -1.5rem;
    padding-right: 1rem;
    color: ${({ completed, touched }) => handleColorType(completed, touched)};
  }
`;

export default FlowCreatePasswordForm;
