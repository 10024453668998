import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import i18n from "../i18n";

import FlowChooseThemeForm from "../components/FlowChooseThemeForm";
import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";
import useRouting from "../hooks/useRouting";
import { patchStorage, retrieveStorageById } from "../helpers/storeService";

const FlowChooseTheme = () => {
  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { id } = useParams();

  useEffect(() => {
    const { data } = retrieveStorageById(id);
    setDefaultValues(data);
  }, []);

  function handleSubmit(values) {
    patchStorage(values, id);
    renderNextRoute();
  }

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          "Your default assistant, Louise, is ready to assist you"
        )}
      />
      <Divider />
      <RightSplit>
        <FlowChooseThemeForm
          handleFormSubmit={val => {
            handleSubmit(val);
          }}
          defaultValues={defaultValues}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
  overflow-y: auto;
  padding: 5rem;

  @media (max-width: 768px) {
    display: block;
    padding: 3rem 5rem;
  }

  @media screen and (max-width: 425px) {
    padding: 3rem 2.5rem;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 100%;
  background: rgba(0, 0, 0, .3);

  @media (max-width: 768px) {
    display: none;
  }
`;

const RightSplit = styled.div`
  width: 50%;
  padding-left: 5rem;
  overflow-y: auto;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding-top: 1px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

export default FlowChooseTheme;
