import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import styled from "styled-components";
import { ActionButton } from "wg-fe-ui";
import * as Yup from "yup";
import i18n from "../i18n";
import FlowOfficeInfoValidationFields from "./FlowOfficeInfoValidationFields";
import { retrieveStorageById } from "../helpers/storeService";

import { cbe, companyName, string } from "../constants/validationSchemas.js";
import useForm from "../hooks/useForm";
import {
  checkIfCbeIsValid,
  checkIfDistributionNameIsValid,
  checkIfFsmaIsValid,
} from "../helpers/apiRouterService";
import debounce from "debounce-promise";

const FlowOfficeInformationForm = ({ handleFormSubmit, defaultValues }) => {
  const { id } = useParams();
  let prevName;

  const SignupSchema = Yup.object().shape({
    company: companyName.test(
      "is-company-name-valid",
      i18n.t("Your office already has an account"),
      async (name) => {
        if (prevName === name) return;
        prevName = name;
        return await debounceCompanyName(name);
      }
    ),
    cbe: cbe.required.test({
      name: "is-cbe-valid",
      test: async function(cbe) {
        const value = await debounceCbe(cbe);
        console.log(value);
        return value !== true
          ? this.createError({
              message: value,
              path: "cbe", // Fieldname
            })
          : true;
      },
    }),
    telephonenr: string.required,
    streetName: string.required,
    municipalityName: string.required,
    postalCode: string.required,
    streetNumber: string.required,
    boxNumber: string.notRequired,
  });

  const debounceCbe = debounce(handleCbeValidation, 1000);

  async function handleCbeValidation(cbe = "") {
    const [cbeResp, cbeStatus] = await checkIfCbeIsValid(
      cbe.split(".").join("")
    );
    const [fsmaResp, fsmaStatus] = await checkIfFsmaIsValid(
      cbe.split(".").join("")
    );
    console.log(fsmaResp, cbeResp);

    if (fsmaResp?.msg === "VALIDATION_ERROR") return i18n.t("incorrect format");
    if (cbeStatus === 404 && fsmaStatus === 404) return true;
    return i18n.t("Your office already has an account");
  }

  const debounceCompanyName = debounce(handleCompanyNameValidation, 1000);

  async function handleCompanyNameValidation(companyName = "") {
    const [, status] = await checkIfDistributionNameIsValid(companyName);
    return status === 404;
  }

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  console.log(errors);

  useEffect(() => {
    const { data } = retrieveStorageById(id);
    setDefaultValues(data);
  }, [defaultValues]);

  function setDefaultValues() {
    Object.keys(defaultValues).map((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
  }

  function formSubmitHandler(e) {
    e.preventDefault();
    console.log("button is reaching function");
    handleSubmit(handleFormSubmit);
  }

  return (
    <Form onSubmit={formSubmitHandler}>
      <FlowOfficeInfoValidationFields
        handleChange={handleChange}
        values={values}
        errors={errors}
        handleFormValues={handleSubmit}
        defaultValues={defaultValues}
      />
      <ButtonContainer>
        <ActionButton
          type="submit"
          value="Submit"
          data-test-id="drivers_information_submit"
        >
          {i18n.t("Next")}
        </ActionButton>
      </ButtonContainer>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: auto;
  align-self: flex-end;

  & button {
    width: 20rem;

    @media (max-width: 768px) {
      margin-top: 5rem;
    }

    @media screen and (max-width: 425px) {
      width: 100%;
      margin-top: 2rem;
    }
  }
`;

FlowOfficeInformationForm.defaultProps = {
  handleFormValues: () => {},
  defaultValues: {},
};

export default FlowOfficeInformationForm;
