import React from "react";
import Main from "./pages/Main.js";
import GlobalStyle from "./resetStyling";
import { ThemeContextProvider } from "./contexts/ThemingContext";
import { Helmet } from "react-helmet";
import i18next from "i18next";
import LogRocket from "logrocket";
import "./i18n";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV !== "development") {
  LogRocket.init("7l7lpz/broker-onboarding");
}

function App() {
  return (
    <div className="App">
      <ThemeContextProvider>
        <GlobalStyle />
        <Helmet>
          <html lang={i18next.language} />
        </Helmet>
        <Main />
      </ThemeContextProvider>
    </div>
  );
}

export default Sentry.withProfiler(App);
