import React from "react";
import styled from "styled-components";
import i18n from "../i18n";
import LoginUserValidationFields from "../components/LoginUserValidationFields";
import debounce from "debounce-promise";
import { useHistory, useLocation } from "react-router";
import { ActionButton } from "wg-fe-ui";
import * as Yup from "yup";
import {
  firstName,
  lastName,
  email,
  captcha
} from "../constants/validationSchemas";

import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";

import useForm from "../hooks/useForm";
import { captchaAuth, checkIfEmailIsValid } from "../helpers/apiRouterService";
import { generateUUID } from "../helpers/uuidService";
import { initializeStorage, patchStorage } from "../helpers/storeService";
import ReactGA from 'react-ga';

const Login = () => {
  const urlParams = new URLSearchParams(useLocation().search);

  if (urlParams.has("lang")) {
    const toSetLanguage = urlParams.get("lang");
    const currentLanguage = i18n.language;
    if (toSetLanguage !== currentLanguage) i18n.changeLanguage(toSetLanguage);
  }

  const history = useHistory();
  const SignupSchema = Yup.object().shape({
    firstName,
    lastName,
    email: email.test(
      "is-email-valid",
      i18n.t("This e-mail address is already in use"),
      async email => {
        return await debounceEmail(email);
      }
    ),
    captcha
  });

  const debounceEmail = debounce(handleEmailValidation, 1000);

  async function handleEmailValidation(email = "") {
    const [, status] = await checkIfEmailIsValid(email);
    return status === 404;
  }

  const { handleChange, values, handleSubmit, errors, setErrors } = useForm({
    validationSchema: SignupSchema,
    change: () => {}
  });

  const onSubmit = ({ firstName, lastName, email, captcha }) => {
    authenticate(firstName, lastName, email, captcha);
  };

  const authenticate = async (firstName, lastName, email, captcha) => {
    const [, status] = await captchaAuth({
      captcha,
      email,
      firstName,
      lastName,
      askBrokerId: "dc8bb689-1ac9-4e8b-8b42-5a4bdc2ce97d"
    });

    if (status !== 200) return;
    const id = generateUUID();
    initializeStorage(id);
    patchStorage({ firstName, lastName, email: email.toLowerCase() }, id);
    ReactGA.ga(`trialonboarding.set`, 'userId', email);
    ReactGA.ga(`trialonboarding.send`, 'event', {
      eventCategory: 'Onboarding Flow',
      eventAction: 'Logged in',
      eventLabel: i18n.language,
    });
    window.Autopilot.run('associate', {
      _simpleAssociate: true,
      Email: email.toLowerCase(),
      FirstName: firstName,
      LastName: lastName,
      LeadSource: 'Trial Onboarding',
      _autopilot_list: 'contactlist_759dc328-80e4-4ac8-9ff1-27e79919187c',
      custom: {
        'string--Language': i18n?.language.toUpperCase(),
      },
    });
    await history.push("/session");
  };

  const formSubmitHandler = e => {
    e.preventDefault();
    handleSubmit(onSubmit);
  };

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          "Hello Im Louise Glad to see that as a broker you are committed to digitization in the sector To get started can I have your name and e-mail address"
        )}
      />
      <Divider />
      <RightSplit>
        <Form onSubmit={formSubmitHandler} data-test-id="social_login_form">
          <LoginUserValidationFields
            handleChange={handleChange}
            values={values}
            errors={errors}
          />
          <CaptchaError>{errors.captcha}</CaptchaError>
          <ButtonContainer>
            <ActionButton
              type="submit"
              value="Login"
              data-test-id="social_login_submit"
            >
              {i18n.t("Next")}
            </ActionButton>
          </ButtonContainer>
        </Form>
      </RightSplit>
    </SplitContainer>
  );
};

const Divider = styled.div`
  width: 1px;
  height: 100%;
  background: rgba(0, 0, 0, .3);

  @media (max-width: 768px) {
    display: none;
  }
`;

const CaptchaError = styled.p`
  color: #f74040;
  text-align: right;
  font-size: 1.2rem;
  margin-top: .5rem;
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
  overflow-y: auto;
  padding: 5rem;

  @media (max-width: 768px) {
    display: block;
    padding: 3rem 5rem;
  }

  @media screen and (max-width: 425px) {
    padding: 3rem 2.5rem;
  }
`;

const RightSplit = styled.div`
  width: 50%;
  padding-left: 5rem;
  overflow-y: auto;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding-top: 1px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }

  ${'' /* @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  } */}
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: auto;
  align-self: flex-end;

  & button {
    width: 20rem;

    @media (max-width: 768px) {
      margin-top: 5rem;
    }

    @media screen and (max-width: 425px) {
      width: 100%;
      margin-top: 2rem;
    }
  }
`;

export default Login;
