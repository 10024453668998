import React, { useState } from "react";
import styled from "styled-components";
import { ActionButton } from "wg-fe-ui";
import i18n from "../i18n";
import ReactGA from 'react-ga';

const FlowOfficeInformationForm = ({ handleFormSubmit }) => {
  const [errorMsg, setErrorMsg] = useState("");

  function formSubmitHandler(e) {
    console.log("using form");

    e.preventDefault();
    handleFormSubmit();
  }

  function handleError() {
    ReactGA.ga(`trialonboarding.send`, 'event', {
      eventCategory: 'Onboarding Flow',
      eventAction: 'Wished to personalize avatar',
      eventLabel: i18n.language,
    });
    setErrorMsg(i18n.t("Not available yet"));
    return;
  }
  const continueQuestion = i18n.t(
    "Would you like to continue with your default assistant Louise or customize your own assistant"
  );

  return (
    <Form>
      <QuestionContainer>
        <StyledP>
          {i18n.t(
            "Your digital assistant will represent you and help you communicate with your clients"
          )}
        </StyledP>
        <StyledP dangerouslySetInnerHTML={{ __html: continueQuestion }} />

        <ButtonsContainer>
          <form onSubmit={formSubmitHandler}>
            <StyledActionButton type="submit" value="Submit">
              {i18n.t("Continue with Louise")}
            </StyledActionButton>
          </form>
          <ErrorMsgContainer>
            <StyledActionButton className="customize" onClick={handleError}>
              {i18n.t("Customize assistant")}
            </StyledActionButton>
            <ErrorMsg>{errorMsg}</ErrorMsg>
          </ErrorMsgContainer>
        </ButtonsContainer>
      </QuestionContainer>
    </Form>
  );
};

const ErrorMsgContainer = styled.div``;

const ErrorMsg = styled.p`
  color: #f74040;
  font-size: 1.2rem;
  height: 1rem;
  margin-bottom: 1rem;
  text-align: end;
  max-width: 16.5rem;
  margin: auto;
  margin-top: -1rem;
`;

const StyledActionButton = styled(ActionButton)`
  display: inline;
  font-weight: 400;
  font-size: 1.4rem;
  margin: 2rem;
  min-width: 17rem;
  &.customize {
    background-color: white;
    border: 2px solid #ff8000;
    box-sizing: border-box;
    color: #ff8000;
  }
`;

const StyledP = styled.p`
  letter-spacing: 0.01rem;
  margin-bottom: 4rem;
  > span {
    font-weight: 800;
  }
`;

const QuestionContainer = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin: auto;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 1160px) and (min-width: 768px) {
    display: block;
  }
  @media (max-width: 600px) {
    display: block;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  ${'' /* display: grid; */}
  ${'' /* grid-template-rows: 51rem; */}
  height: 100%;
`;

export default FlowOfficeInformationForm;
